<template>
  <b-card>
    <!-- <load-profile></load-profile> -->
    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
      {{ $t("breadcrumb.updatePassword") }}
    </b-card-title>

    <validation-observer ref="updatePasswordForm" #default="{ invalid }">
      <n-form-confirmation
        :key="`updatePasswordForm-${key}`"
        @submit="validateForm"
        :form="$refs.updatePasswordForm"
        :disabled="invalid"
      >
        <n-input ref="input" :fields="fields" v-model="data"></n-input>

        <b-row>
          <b-col cols="12" class="text-right">
            <n-button-loading
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mt-1"
              :loading="loading"
              :disabled="invalid"
            >
              {{ $t("button.save") }}
            </n-button-loading>
          </b-col>
        </b-row>
      </n-form-confirmation>
    </validation-observer>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BCardTitle } from "bootstrap-vue";
import NButtonLoading from "@/components/NButtonLoading";
import Ripple from "vue-ripple-directive";
import Repository from "@/repositories/RepositoryFactory";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./updatePasswordForm";

const ProfileRepository = Repository.get("profile");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    NButtonLoading,
    NFormConfirmation,
    NInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      key: 1,
      data: {
        oldPassword: "",
        newPassword: "",
        retypeNewPassword: "",
      },
      loading: false,
    };
  },
  methods: {
    validateForm() {
      this.$refs.updatePasswordForm.validate().then((success) => {
        if (success) {
          this.submit();
        }
      });
    },
    submit() {
      this.loading = true;
      ProfileRepository.updatePassword(this.data)
        .then((response) => {
          this.data = {
            oldPassword: "",
            newPassword: "",
            retypeNewPassword: "",
          };
          this.key++;
        })
        .catch((error) => {
          if (error.response?.data?.message) {
            this.$refs.updatePasswordForm.setErrors(
              error.response?.data?.message
            );
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = FormInput;
    return {
      fields,
    };
  },
};
</script>
